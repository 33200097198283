<template>
  <v-container>
    <v-row>
      <v-col
        v-for="item in get_json(data)" :key="item.num"
        cols="12"
        lg="4"
        md="6"
      >
        <v-card outlined class="my-0 pa-4 p-relative whoxy-block">
          <v-chip v-if="item.registrant_contact" class="whoxy-date secondary mb-2">{{beautifyDate(item.query_time)}}</v-chip>
          <div class="mb-2"><b>Owner:</b> <span>{{item.registrant_contact.full_name}}</span></div>
          <div class="mb-2"><b>Company:</b> <span>{{getField(item.administrative_contact, 'company_name')}}</span></div>
          <div class="mb-2"><b>Domain:</b> <span>{{getField(item.domain_registrar, 'registrar_name')}}</span></div>
          <div class="mb-2"><b>Geolocation:</b> <span>{{getField(item.registrant_contact,'city_name')}}, {{getField(item.registrant_contact, 'state_name')}} {{getField(item.registrant_contact, 'zip_code')}}, {{getField(item.registrant_contact, 'country_name')}}</span></div>
          <div class="mb-2"><b>Email:</b> <span>{{getField(item.administrative_contact, 'email_address')}}</span></div>
          <div class="mb-2"><b>Phone:</b> <span>{{getField(item.administrative_contact, 'phone_number')}}</span></div>
          <div class="mb-2"><b>Email:</b> <span v-html="beautifyEmail(getField(item.administrative_contact, 'email_address'))"></span></div>
          <div class="mb-2"><b>Nameservers:</b> <span>{{item.name_servers.join(', ')}}</span></div>
          <div class="mb-2"><b>Status:</b> <span>{{item.domain_status[0]}}</span></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get_json, beautifyEmail, beautifyDate } from '@/util'
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'WhoXY',

    props: ['data'],

    mounted () {
      console.log(this.data)
    },

    methods: {
      get_json,
      beautifyEmail,
      beautifyDate,

      getField(item, attr) {
        if (item) {
          return item[attr]
        } else {
          return ''
        }
      }
    }
  }
</script>