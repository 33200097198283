<template>
  <v-container 
  >
    <v-data-table
      :headers="hibpHeaders"
      :items="parse_array(data)"
      :items-per-page="5"
      item-key="Email"
      dense
      class="grey-border"
    >
      <template v-slot:item.Email="{ item }">
        <span v-html="beautifyEmail(item.Email)"></span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { 
    parse_array,
    beautifyEmail
  } from '@/util'

  export default {
    name: 'Hibp',

    props: ['data'],

    data: () => ({
      hibpHeaders: [
        {
          value: 'Breach',
          text: 'Breach',
        },
        {
          value: 'Email',
          text: 'Email',
        },
      ],
    }),
    methods: {
      parse_array,
      beautifyEmail,
    }
  }
</script>