<template>
  <v-container
    class="grey-border"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        md="3"
      >
        <b>Country:</b> <span>{{json_data.urlscan_country}}</span>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <b>IP Address:</b> <span>{{json_data.urlscan_ip_address}}</span>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <b>IPV6:</b> <span>{{json_data.urlscan_ipv6}}</span>
      </v-col>
      
      <v-col
        cols="12"
        md="4"
      >
        <b>Server:</b> <span>{{json_data.urlscan_server}}</span>
      </v-col>
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <b>Web Apps:</b> 
        <span class="d-flex flex-wrap">
          <span v-for="app in split(json_data.urlscan_web_apps)">
            <v-chip small outlined class="secondary ma-1 pa-2 mb-2">{{app}}</v-chip>
          </span>
        </span>
      </v-col>
      <v-col
        cols="12"
        class="d-flex"
      >
        <b >Pointed Domains:</b> 
        <span class="d-flex flex-wrap">
          <span v-for="domain in split(json_data.urlscan_pointed_domains)">
            <v-chip small outlined class="secondary ma-1 pa-2 mb-2">{{domain}}</v-chip>
          </span>
         </span>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <b>Number of Requests:</b> <span>{{json_data.urlscan_number_of_requests}}</span>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <b>Ads Blocked:</b> 
        <v-chip outlined class="ml-2" :color="json_data.urlscan_ads_blocked == 1 ? 'green' : 'black'" >{{json_data.urlscan_ads_blocked == 1 ? 'YES' : 'No'}}</v-chip>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <b>HTTP Requests:</b> <span>{{json_data.urlscan_http_requests}}</span>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <b>Unique Country Counts:</b> <span>{{json_data.urlscan_unique_country}}</span>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <b>Malicious Requests:</b> <span>{{json_data.urlscan_malicious_requests}}</span>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import { get_json, split } from '@/util'

  export default {
    name: 'UrlScan',

    props: ['data'],

    methods: {
      get_json,
      split
    },
    computed: {
      json_data () {
        return get_json(this.data)
      }
    }
  }
</script>

