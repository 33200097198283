<template>
  <v-container>
    <div class="mb-3">Assessed on:  <b>{{beautifyDateTime(get_json(data).testTime)}}</b></div>
    <v-data-table
      :headers="ssllabsHeaders"
      :items="parse_array(data).endpoints"
      item-key="Email"
      dense
      hide-default-footer
      class="grey-border"
    >
      <template v-slot:item.no="{ item }">
        <div class="body-1 font-weight-bold">{{get_json(data).endpoints.indexOf(item) + 1}}</div>
      </template>
      <template v-slot:item.grade="{ item }">
        <div class="display-3 font-weight-bold" :class="determinGrateClass(item.grade)">{{item.grade}}</div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
  import { get_json, beautifyDateTime, parse_array } from '@/util'
  import { mapState, mapActions } from 'vuex';

  export default {
    name: "SSLLab",

    props: ['data'],

    computed: {
      ...mapState(['page'])
    },

    data() {
      return {
        ssllabsHeaders: [
          {
            value: 'no',
            text: '',
            align: 'center',
            class: 'font-weight-bold'
          },
          {
            value: 'ipAddress',
            text: 'Server',
          },
          {
            value: 'grade',
            text: 'Grade',
            align: 'center',
          },
        ],
      }
    },

    methods: {
      get_json,
      beautifyDateTime,
      parse_array,

      getPageNum (_page) {
        localStorage.setItem('page', _page)
      },

      determinGrateClass (grade) {
        let color = 'black'
        switch (grade) {
          case 'A':
            color = 'success'
            break
          case 'B':
            color = 'orange'
            break
          case 'C':
            color = 'purple'
            break
          default:
            color = 'red'
            break
        }

        return color + '--text'
      },
    }
  }
</script>